import { Button, Form, Input, Select, Modal } from 'antd'
import React, { useState, useEffect, useMemo } from 'react'
import { BoxForm, CustomUploadfile, BoxBtn, BoxBtnInMap, BoxHeader, CustomModal, CustomPopover } from './form.style'
import TextArea from 'antd/lib/input/TextArea'
import UploadFile from 'components/Inputs/UploadFile'
import Viewmap from './MapSelect'
import { conversDate } from 'core/utils/DateTimeFormat'
import ExclamationCircle from 'assets/images/svg/offline/exclamation-circle.js'
import moment from 'moment'
import { getList, postData } from 'core/action/collection'
import { ReactComponent as CloseModelMap } from '../image/svg/CloseModelMap.svg'
import { ReactComponent as Back } from '../image/svg/Back.svg'
import useGetAddress from 'core/hooks/useGetAddress'
import NakhonRatchasima from 'core/utils/NakhonRatchasima_municipality.json'
import axios from 'axios'
import iconInfo from '../image/svg/icon-info.svg'
import BeforeIcon from '../image/png/Before Success.png'
import { useNavigate } from 'react-router-dom'
const { Option } = Select

const Index = () => {
  const [formAntd] = Form.useForm()
  const navigate = useNavigate()
  const [cover, setCover] = useState([])
  const [typeComplant, setTypeComplant] = useState([])
  const [subCatagory, setSubCatagory] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpenError, setIsModalOpenError] = useState(false)
  const [clickedLatLng, setClickedLatLng] = React.useState(null)
  const [loaddingCraete, setloaddingCraete] = useState(false)
  const [disableBtn, setDisableBtn] = useState(true)
  const [leaveModalOpen, setLeaveModalOpen] = useState(false)
  const [meLocation, setMeLocation] = useState({ lat: 0, lng: 0 })
  const [province, setProvince] = useState([])
  const [district, setDistrict] = useState([])
  const [subDistrict, setSubDistrict] = useState([])
  const [centerMap, setCenterMap] = useState({ lat: 0, lng: 0 })
  const [zoomMap, setZoomMap] = useState(10)
  const [autocompleteValue, setAutocompleteValue] = useState(null)
  const [searchValue, setSearchValue] = useState('')

  const address = useGetAddress(clickedLatLng ? clickedLatLng : meLocation)
  let dataNow = conversDate(moment().unix())
  let arrArear = []
  NakhonRatchasima?.features[0]?.geometry?.coordinates[0]?.map((item, index) => {
    arrArear.push({ lat: item[1], lng: item[0] })
  })
  useEffect(() => {
    const fetchUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const { latitude, longitude } = position.coords
            setMeLocation({ lat: latitude, lng: longitude })
            setCenterMap({ lat: latitude, lng: longitude })
          },
          error => {
            console.error('Error getting location:', error.message)
          }
        )
      } else {
        console.error('Geolocation is not supported by this browser.')
      }
    }
    fetchUserLocation() // Call the function when the component mounts
  }, [])

  useEffect(() => {
    getMasterData()
    getProvince()
  }, [])

  useEffect(() => {
    handleChangeForm()
  }, [cover])

  const getProvince = async () => {
    axios.get(`${process.env.REACT_APP_BASEURL}/master/api/v1/all/province`).then(res => {
      if (res?.data?.code === 200) {
        setProvince(res.data.data)
      }
    })
  }

  const changeProvince = val => {
    axios.get(`${process.env.REACT_APP_BASEURL}/master/api/v1/all/district/${val}`).then(res => {
      if (res?.data?.code === 200) {
        setDistrict(res.data.data)
        formAntd.setFieldValue('district', null)
        formAntd.setFieldValue('sub_district', null)
        formAntd.setFieldValue('zip_code', null)
      }
    })
  }
  const changeDistrict = val => {
    axios.get(`${process.env.REACT_APP_BASEURL}/master/api/v1/all/sub-district/${val}`).then(res => {
      if (res?.data?.code === 200) {
        setSubDistrict(res.data.data)
        formAntd.setFieldValue('sub_district', null)
        formAntd.setFieldValue('zip_code', null)
        setDisableBtn(true)
      }
    })
  }

  const changeSubDistrict = val => {
    subDistrict?.map(item => {
      if (item?.id === val) {
        formAntd.setFieldValue('zip_code', item?.postcode)
      }
    })
  }

  const checkArear = val => {
    const bermudaTriangle = new window.google.maps.Polygon({
      paths: arrArear,
    })
    const pointToCheck = new window.google.maps.LatLng(val?.lat, val?.lng)
    if (
      window.google.maps.geometry &&
      window.google.maps.geometry.poly &&
      window.google.maps.geometry.poly.containsLocation
    ) {
      const isInside = window.google.maps.geometry.poly.containsLocation(pointToCheck, bermudaTriangle)
      return isInside
    } else {
      console.error("Google Maps 'containsLocation' method is not available.")
      return false // Return false assuming the location is outside if the check cannot be performed
    }
  }
  const getMasterData = async () => {
    const resCategories = await getList({
      schema: '/master/api/v1/all/eservice',
      params: '',
    })
    setTypeComplant(resCategories?.items)
  }
  let masterDataTypeComplant = typeComplant?.map(item => {
    return {
      label: (
        <div>
          <img src={item?.icon_url} style={{ marginRight: '10px', width: '14px', height: '14px' }} />
          {item.name}
        </div>
      ),
      value: item.id,
    }
  })

  const validatePhone = async () => {
    let valForm = formAntd.getFieldsValue()
    // const isValidThaiPhoneNumber = /^0\d{2}-\d{3}-\d{4}$/.test(valForm?.tel)
    // if (valForm?.tel?.length === 12 && isValidThaiPhoneNumber) {
    // handleChangeForm()
    //   formAntd.setFields([
    //     {
    //       name: 'tel', // required
    //       value: formAntd.getFieldValue('tel'), //optional
    //       errors: [],
    //     },
    //   ])
    // } else if (valForm?.tel?.length === 12 && !isValidThaiPhoneNumber) {
    //   formAntd.setFields([
    //     {
    //       name: 'tel', // required
    //       value: formAntd.getFieldValue('tel'), //optional
    //       errors: ['รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง'],
    //     },
    //   ])
    // } else {
    //   setDisableBtn(true)
    //   formAntd.setFields([
    //     {
    //       name: 'tel', // required
    //       value: formAntd.getFieldValue('tel'), //optional
    //       errors: ['กรุณากรอกหมายเลขโทรศัพท์ 10 หลัก'],
    //     },
    //   ])
    // }
  }

  const phoneNumberAutoFormat = phoneNumber => {
    const number = phoneNumber?.trim()?.replace(/[^0-9]/g, '')
    if (number.length < 4) return number
    if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, '$1-$2')
    if (number.length < 11) return number.replace(/(\d{3})(\d{3})(\d{1})/, '$1-$2-$3')
    return number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
  }

  const validateFristName = () => {
    let valForm = formAntd.getFieldsValue()
    if (valForm.fristName?.length) {
      if (/^[ก-๛ ]+$/.test(valForm.fristName) && valForm.fristName?.length <= 255) {
        handleChangeForm()
        return Promise.resolve()
      } else if (valForm?.fristName?.length > 255) {
        setDisableBtn(true)
        return Promise.reject('กรุณากรอกไม่เกิน 255 ตัวอักษร')
      } else {
        setDisableBtn(true)
        return Promise.reject('กรุณากรอกภาษาไทยเท่านั้น')
      }
    }
  }
  const validateLastName = () => {
    let valForm = formAntd.getFieldsValue()
    if (valForm.lastName?.length) {
      if (/^[ก-๛ ]+$/.test(valForm.lastName) && valForm.lastName?.length <= 255) {
        handleChangeForm()
        return Promise.resolve()
      } else if (valForm?.lastName?.length > 255) {
        setDisableBtn(true)
        return Promise.reject('กรุณากรอกไม่เกิน 255 ตัวอักษร')
      } else {
        setDisableBtn(true)
        return Promise.reject('กรุณากรอกภาษาไทยเท่านั้น')
      }
    }
  }
  const validateComplantName = () => {
    let valForm = formAntd.getFieldsValue()
    if (valForm.title_complaint?.length) {
      if (valForm.title_complaint?.length <= 255) {
        handleChangeForm()
        return Promise.resolve()
      } else if (valForm?.title_complaint?.length > 255) {
        setDisableBtn(true)
        return Promise.reject('กรุณากรอกไม่เกิน 255 ตัวอักษร')
      } else {
        setDisableBtn(true)
        return Promise.reject('กรุณากรอกภาษาไทยเท่านั้น')
      }
    }
  }

  const validateAdress = () => {
    let valForm = formAntd.getFieldsValue()
    if (valForm.address?.length) {
      if (valForm.address?.length <= 255) {
        handleChangeForm()
        return Promise.resolve()
      } else if (valForm?.address?.length > 255) {
        setDisableBtn(true)
        return Promise.reject('กรุณากรอกไม่เกิน 255 ตัวอักษร')
      }
    }
  }

  const validateDescription = () => {
    let valForm = formAntd.getFieldsValue()
    if (valForm?.description?.length) {
      if (valForm?.description?.length <= 255) {
        handleChangeForm()
        return Promise.resolve()
      } else if (valForm?.description?.length > 255) {
        setDisableBtn(true)
        return Promise.reject('กรุณากรอกไม่เกิน 255 ตัวอักษร')
      }
    }
  }

  const handleChangeForm = () => {
    let valForm = formAntd.getFieldsValue()
    if (valForm.tel) {
      formAntd.setFieldValue('tel', phoneNumberAutoFormat(valForm.tel))
    }

    const Telx = valForm?.tel?.replace(/-/g, '') // ลบขีด (-) ทั้งหมดออก

    const telRegex = /^(0[689]\d{8}|044\d{6})$/

    const numberRegex = /^\d+$/

    if (valForm?.tel?.length > 3) {
      if (!telRegex.test(Telx)) {
        console.log('รูปแบบข้อมูลไม่ถูกต้อง')
        formAntd.setFields([
          {
            name: 'tel',
            value: formAntd.getFieldValue('tel'),
            errors: ['รูปแบบข้อมูลไม่ถูกต้อง'], // แสดง error เมื่อรูปแบบไม่ถูกต้อง
          },
        ])
      } else {
        // ล้างข้อผิดพลาดหากรูปแบบถูกต้อง
        formAntd.setFields([
          {
            name: 'tel',
            value: formAntd.getFieldValue('tel'),
            errors: [], // Clear errors
          },
        ])
      }
    } else {
      // ยกเลิก error เมื่อตัวอักษรน้อยกว่า 3 ตัว
      formAntd.setFields([
        {
          name: 'tel',
          errors: [], // Clear errors
        },
      ])
    }

    let arrFile = []
    cover &&
      cover?.map(item => {
        arrFile.push(item?.img)
      })
    const isValidThaiPhoneNumber = /^0\d{2}-\d{3}-\d{4}$/.test(valForm?.tel) && valForm?.tel?.length === 12
    const firstName =
      /^[ก-๛ ]+$/.test(valForm.fristName) && valForm.fristName?.length <= 255 && valForm.fristName?.length > 0
    const lastName =
      /^[ก-๛ ]+$/.test(valForm.lastName) && valForm.lastName?.length <= 255 && valForm.lastName?.length > 0
    const address = valForm.address?.length <= 255 && valForm.address?.length > 0
    const title_complaint = valForm.title_complaint?.length <= 255 && valForm.title_complaint?.length > 0
    const description =
      /^[ก-๛ ]+$/.test(valForm.description) && valForm.description?.length <= 255 && valForm.description?.length > 0
    if (
      !firstName ||
      !lastName ||
      !isValidThaiPhoneNumber ||
      !valForm?.type_of_complaint?.length ||
      // !valForm?.sub_type_of_complaint?.length ||
      !title_complaint ||
      !arrFile?.length ||
      // (valForm?.type_of_complaint === '1735509869718401030' && !valForm?.number_user_water) ||
      // (valForm?.type_of_complaint === '1735509869739372551' && !description) ||
      !address ||
      !valForm?.province?.length ||
      !valForm?.district?.length ||
      !valForm?.sub_district?.length
    ) {
      setDisableBtn(true)
    } else {
      setDisableBtn(false)
    }
  }

  const handleChangeCategory = async val => {
    setSubCatagory([])
    formAntd.setFieldValue('sub_type_of_complaint', null)
    const res = await getList({
      schema: `/master/api/v1/all/subcategories/${val}`,
      params: '',
    })
    res?.items.map((value, index, array) => {
      setSubCatagory(per => [
        ...per,
        {
          label: value?.name,
          value: value?.ID,
        },
      ])
    })
    setDisableBtn(true)
  }

  const handleUpdateStatusFile = async code => {
    cover.map(item => {
      const body = {
        status: 'active',
        url: item?.img,
      }
      axios
        .put(process.env.REACT_APP_MEDIA + '/medias', body)
        .then(res => {})
        .catch(err => {
          setloaddingCraete(false)
          console.log('err', err)
        })
    })
    setTimeout(() => {
      setloaddingCraete(false)
      navigate('/e-service/bof-eservice', {
        state: {
          code: code,
        },
      })
    }, 2000)
  }

  const handleClickMap = event => {
    if (isModalOpen) {
      const cord = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      }

      if (checkArear(cord)) {
        setAutocompleteValue('')
        setSearchValue('')
        setClickedLatLng({
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        })
        setCenterMap({
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        })
        setZoomMap(14)
      } else {
        setIsModalOpenError(true)
      }
    }
  }

  const handleCreateComplant = async () => {
    setloaddingCraete(true)
    let isInsidePolygon = await checkArear({
      lat: clickedLatLng?.lat || meLocation?.lat,
      lng: clickedLatLng?.lng || meLocation?.lng,
    })
    if (!isInsidePolygon) {
      setIsModalOpenError(true)
      setDisableBtn(true)
    } else {
      const value = formAntd.getFieldsValue()
      let arrFile = []
      cover.map(item => {
        arrFile.push(item?.img)
      })
      const body = {
        address: autocompleteValue ? autocompleteValue : address[2]?.formatted_address,
        type_of_complaint: value?.type_of_complaint,
        channel: 'Web application',
        title_complaint: value?.title_complaint,
        coordinates: {
          latitude: clickedLatLng?.lat ? clickedLatLng?.lat : meLocation?.lat,
          longitude: clickedLatLng?.lng ? clickedLatLng?.lng : meLocation?.lng,
        },
        description: value?.description,
        district_id: value?.district,
        // line_id: 'string',
        media_url: arrFile,
        mobile_number: value?.tel?.replace(/-/g, ''),
        name: {
          first_name: value?.fristName,
          last_name: value?.lastName,
        },
        org_id: '19118133238380',
        province_id: value?.province,
        status_data: [
          {
            description: 'รับเรื่อง',
            name: 'received',
          },
        ],
        sub_district_id: value?.sub_district,
        zib_code: value?.zip_code,
      }
      const token = localStorage.getItem('access_token')
      axios
        .post(`${process.env.REACT_APP_BASEURL}/complaint/api/v1/e-service/complaint`, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          if (res?.data?.code === 201) {
            handleUpdateStatusFile(res?.data?.code)
          } else {
            setloaddingCraete(false)
          }
        })
        .catch(err => {
          setloaddingCraete(false)
          console.log('err', err)
        })
    }
  }

  const handleOk = () => {
    if (clickedLatLng?.lat || clickedLatLng?.lng) {
      setIsModalOpen(false)
      handleChangeForm()
    } else {
      if (checkArear(meLocation)) {
        setClickedLatLng({
          lat: meLocation?.lat,
          lng: meLocation?.lng,
        })
        setIsModalOpen(false)
        handleChangeForm()
      } else {
        setIsModalOpenError(true)
      }
    }
  }
  const handleCancel = () => {
    setIsModalOpen(false)
    // setSearchValue('')
  }

  return (
    <>
      <Form layout="vertical" form={formAntd} onValuesChange={handleChangeForm}>
        <BoxHeader>
          <Back style={{ cursor: 'pointer', width: '80px' }} onClick={() => setLeaveModalOpen(true)} />{' '}
          <p>เพิ่มเรื่องร้องเรียน</p>
        </BoxHeader>
        <BoxForm>
          <div className="wrap-form">
            <Form.Item name="date" label={<div>วันที่</div>}>
              <Input defaultValue={dataNow} disabled />
            </Form.Item>
            <Form.Item
              name="fristName"
              label={
                <div>
                  ชื่อ <span style={{ color: 'red' }}>*</span>
                </div>
              }
              rules={[{ validator: validateFristName }]}
            >
              <Input placeholder="โปรดระบุชื่อ ของผู้ขอรับบริการ" />
            </Form.Item>
            <Form.Item
              name="lastName"
              label={
                <div>
                  นามสกุล <span style={{ color: 'red' }}>*</span>
                </div>
              }
              rules={[{ validator: validateLastName }]}
            >
              <Input placeholder="โปรดระบุนามสกุล ของผู้ขอรับบริการ" />
            </Form.Item>
            <Form.Item
              name="tel"
              label={
                <div>
                  เบอร์โทร <span style={{ color: 'red' }}>*</span>
                </div>
              }
            >
              <Input placeholder="0XX-XXX-XXXX" maxLength={12} onChange={validatePhone} />
            </Form.Item>
            <div style={{ fontSize: '20px', fontWeight: 500, marginBottom: '10px' }}>ที่อยู่ของผู้ขอรับบริการ</div>
            <Form.Item
              name="address"
              label={
                <div>
                  รายละเอียดที่อยู่ <span style={{ color: 'red' }}>*</span>
                </div>
              }
              rules={[{ validator: validateAdress }]}
            >
              <Input placeholder="กรอกที่อยู่ของคุณ" />
            </Form.Item>
            <Form.Item
              name="province"
              label={
                <div>
                  จังหวัด <span style={{ color: 'red' }}>*</span>
                </div>
              }
            >
              <Select
                placeholder="เลือกจังหวัด"
                onSelect={changeProvince}
                filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                showSearch
              >
                {province.map((item, index) => (
                  <Option key={item?.id}>{item?.province_name?.th}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="district"
              label={
                <div>
                  อำเภอ <span style={{ color: 'red' }}>*</span>
                </div>
              }
            >
              <Select
                placeholder="เลือกอำเภอ"
                disabled={formAntd.getFieldValue('province') ? false : true}
                showSearch
                filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onSelect={changeDistrict}
              >
                {district.map((item, index) => (
                  <Option key={item?.id}>{item?.district_name?.th}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="sub_district"
              label={
                <div>
                  ตำบล <span style={{ color: 'red' }}>*</span>
                </div>
              }
            >
              <Select
                onSelect={changeSubDistrict}
                placeholder="เลือกตำบล"
                disabled={formAntd.getFieldValue('district') ? false : true}
                filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                showSearch
              >
                {subDistrict.map((item, index) => (
                  <Option key={item?.id}>{item?.sub_district_name?.th}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="zip_code"
              label={
                <div>
                  รหัสไปรษณีย์ <span style={{ color: 'red' }}>*</span>
                </div>
              }
              disabled
            >
              <Input disabled placeholder="รหัสไปรษณีย์" />
            </Form.Item>
            <div style={{ fontSize: '20px', fontWeight: 500, marginBottom: '10px' }}>รายละเอียดเรื่องขอรับบริการ</div>

            <Form.Item
              name="type_of_complaint"
              label={
                <div>
                  ประเภทขอรับบริการ <span style={{ color: 'red' }}>*</span>
                </div>
              }
            >
              <Select
                placeholder="กรุณาเลือก"
                options={masterDataTypeComplant}
                // onSelect={val => {
                //   setTimeout(() => {
                //     handleChangeCategory(val, 'category')
                //   }, 500)
                // }}
                onClear={val => setSubCatagory([])}
                allowClear
              />
            </Form.Item>
            {/* {subCatagory?.length > 0 && (
              <Form.Item
                name="sub_type_of_complaint"
                label={
                  <div>
                    ประเภทย่อย <span style={{ color: 'red' }}>*</span>
                  </div>
                }
              >
                <Select placeholder="ประเภทย่อย" options={subCatagory} optionLabelProp="label" allowClear />
              </Form.Item>
            )} */}
            {/* {formAntd.getFieldValue('type_of_complaint') === '1735509869718401030' && (
              <Form.Item
                name="number_user_water"
                label={
                  <div>
                    เลขที่ผู้ใช้น้ำ <span style={{ color: 'red' }}>*</span>
                  </div>
                }
              >
                <Input placeholder="เลขที่ผู้ใช้น้ำ" />
              </Form.Item>
            )} */}
            <Form.Item
              name="title_complaint"
              rules={[{ validator: validateComplantName }]}
              label={
                <div>
                  เรื่องขอรับบริการ <span style={{ color: 'red' }}>*</span>
                </div>
              }
            >
              <Input placeholder="โปรดระบุเรื่องร้องเรียนสั้นๆ เช่น “น้ำไม่ไหล” ,“ต้นไม้เกี่ยว..." />
            </Form.Item>
            <Form.Item
              name="description"
              rules={[{ validator: validateDescription }]}
              label={
                <div>
                  รายละเอียดเพิ่มเติม{' '}
                  {formAntd.getFieldValue('type_of_complaint') === '1735509869739372551' && (
                    <span style={{ color: 'red' }}>*</span>
                  )}
                </div>
              }
            >
              <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="รายละเอียดเพิ่มเติม" />
            </Form.Item>
            <Form.Item name="media">
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div>
                  อัปโหลดรูปภาพและวิดีโอ (อัปโหลดได้ไม่เกิน 3 ไฟล์) <span style={{ color: 'red' }}>*</span>
                </div>
                <CustomPopover
                  placement="leftTop"
                  content={
                    <div style={{ marginTop: '6px' }}>
                      <p>ภาพที่อัปโหลดต้องเป็น “ภาพที่เกี่ยวข้องกับเหตุการณ์”</p>
                    </div>
                  }
                >
                  <img src={iconInfo} style={{ width: 16, height: 16, cursor: 'pointer' }} />
                </CustomPopover>
              </div>
              <CustomUploadfile>
                <UploadFile
                  accept={{
                    'image/jpeg': [],
                    'image/png': [],
                    'video/mp4': [],
                  }}
                  keys={1}
                  files={cover}
                  isCrop={false}
                  maxFile={3}
                  maxSize={10 * 1024 * 1024}
                  typeUpload={'imagevdo'}
                  text={
                    'ไฟล์ที่อัปโหลดต้องเป็นไฟล์ .jpeg .jpg .png มีขนาดไม่เกิน 10MB ต่อภาพ และ .mp4 มีขนาดไม่เกิน 100MB ต่อวิดีโอ'
                  }
                  onFileRetuen={(files, file) => {
                    let _image = [...files]
                    _image.push(file)
                    setCover(_image)
                  }}
                  onFileRemove={(files, res) => {
                    let _image = [...files]
                    _image = _image.filter((r, i) => i !== res.index)
                    setCover(_image)
                  }}
                />
              </CustomUploadfile>
            </Form.Item>
            <div>
              <div style={{ marginBottom: '10px' }}>สถานที่แจ้งเรื่อง</div>
              <Viewmap
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                clickedLatLng={clickedLatLng}
                setClickedLatLng={setClickedLatLng}
                meLocation={meLocation}
                address={address[2]?.formatted_address}
                arrArear={arrArear}
                setIsModalOpenError={setIsModalOpenError}
                handleOk={handleOk}
                handleClickMap={handleClickMap}
                checkArear={checkArear}
                centerMap={centerMap}
                setCenterMap={setCenterMap}
                setZoomMap={setZoomMap}
                zoomMap={zoomMap}
                autocompleteValue={autocompleteValue}
                setAutocompleteValue={setAutocompleteValue}
                setSearchValue={setSearchValue}
                searchValue={searchValue}
              />
            </div>
          </div>

          <Modal
            open={isModalOpen}
            closeIcon={<CloseModelMap onClick={handleCancel} />}
            className="model-map-create-complant"
            title="เลือกตำแหน่งที่ตั้ง"
            onCancel={handleCancel}
            footer={null}
            bodyStyle={{ height: '80vh', width: '90vw', borderRadius: '10px' }}
            style={{ borderRadius: '10px' }}
            centered
          >
            <Viewmap
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              clickedLatLng={clickedLatLng}
              setClickedLatLng={setClickedLatLng}
              meLocation={meLocation}
              address={address[2]?.formatted_address}
              arrArear={arrArear}
              setIsModalOpenError={setIsModalOpenError}
              handleOk={handleOk}
              handleClickMap={handleClickMap}
              checkArear={checkArear}
              centerMap={centerMap}
              setCenterMap={setCenterMap}
              setZoomMap={setZoomMap}
              zoomMap={zoomMap}
              autocompleteValue={autocompleteValue}
              setAutocompleteValue={setAutocompleteValue}
              setSearchValue={setSearchValue}
              searchValue={searchValue}
            />
          </Modal>
          <Modal
            closable={false}
            open={isModalOpenError}
            footer={null}
            className="model-map-create-complant-error"
            centered
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontFamily: 'Prompt , sans-serif',
                textAlign: 'center',
              }}
            >
              <div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '15%',
                  }}
                >
                  <img src={BeforeIcon} style={{ width: '35px', height: '35px' }} />
                </div>
                <p style={{ fontSize: '20px', margin: 0 }}>ไม่อยู่ในเขตที่รับผิดชอบ</p>
                <p style={{ fontSize: '16px', color: '#C4C4C4', marginBottom: '15%' }}>กรุณาเลือกตำแหน่งใหม่</p>
                <BoxBtnInMap>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      style={{ width: '100px' }}
                      onClick={() => {
                        setIsModalOpenError(false)
                        setloaddingCraete(false)
                      }}
                    >
                      ตกลง
                    </Button>
                  </div>
                </BoxBtnInMap>
              </div>
            </div>
          </Modal>
        </BoxForm>
        <BoxBtn>
          <Button
            className="btn-submit-complant"
            type="primary"
            disabled={disableBtn}
            loading={loaddingCraete}
            onClick={() => handleCreateComplant()}
          >
            ส่งเรื่องร้องเรียน
          </Button>
        </BoxBtn>
        <CustomModal
          transitionName=""
          centered
          open={leaveModalOpen}
          onOk={() => {
            setLeaveModalOpen(false)
            navigate('/e-service/bof-eservice')
          }}
          onCancel={() => setLeaveModalOpen(false)}
          closable={false}
          okText="ยืนยัน"
          cancelText="ยกเลิก"
        >
          <div style={{ paddingBottom: '10px' }}>
            <ExclamationCircle />
          </div>
          <p
            style={{
              color: '#001F34',
              fontSize: '16px',
              marginBottom: '2px',
              fontWeight: '400',
            }}
          >
            คุณต้องการออกจากหน้านี้?
          </p>
          <p
            style={{
              color: '#597081',
              fontSize: '14px',
              fontWeight: '300',
            }}
          >
            หากคุณออกจากหน้านี้ข้อมูลจะไม่ถูกบันทึก
          </p>
        </CustomModal>
      </Form>
    </>
  )
}

export default Index
